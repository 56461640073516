import React, { Component, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Image from "react-bootstrap/Image"
import Card from "./../../components/card/card"
import { useMediaQuery } from "react-responsive"
import Button from "./../../components/button/button"
import Header1 from "./../../components/header1/header1"
import Paragraph from "../../components/paragraph/paragraph"
import ImageBlock from "./../../components/imageBlock/imageBlock"
import Map from "./../../components/map/map"
import Nav from "./../../components/navbar/navbar"
import Grid from "@material-ui/core/Grid"
import Footer from "./../../components/footer/footer"
import ContactForm from './../../components/contactForm/contactForm'
import { Hidden } from "@material-ui/core"
import Carousel from "react-elastic-carousel";

var headerImage = require("./../../assets/aboutOffsets/header.jpg")
const padding = 30

export default function Results() {
  return (
    <div>
      <Nav selected="forests" />

      <div style={{ width: "100%", height: "30vh", position: "relative" }}>
        <img
          src={headerImage}
          style={{
            height: "30vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "30vh",
            display: "flex",
          }}
        >
          <Grid
            container
            style={{
              alignItems: "center",
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Header1
                style={{
                  color: "white",
                  fontSize: 50,
                }}
              >
                Offset Overview
              </Header1>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Grid container style={{ paddingTop: 40}}>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid container xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Header1
                style={{
                  color: "#22422B",
                }}
              >
                Carbon Offsets Neutralize Emissions
              </Header1>
              <Paragraph style={{ lineHeight: 1.6 }}>
              Carbon offsets provide real reductions in greenhouse gas (GHG) emissions by reducing, absorbing or avoiding the release of carbon dioxide. Carbon Down primarily supports forestry projects, as they both offset emissions and provide the additional environmental benefits of habitat preservation and restoration.
                <br />
                <br />
                We fund offset projects that follow strict environmental
                standards that effectively offset carbon. Our verified projects
                require that an independent third party goes to the project
                location to verify that the project has offset the amount of
                carbon originally estimated.
              </Paragraph>
            </Grid>
            <Hidden smDown>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <img
                src={require("./../../assets/hudsonSummary.jpg")}
                style={{
                  width: "100%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <hr />
            </Grid>

           
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Header1
                style={{
                  color: "#22422B",
                }}
              >
                HOW CARBON OFFSet Projects are Created
              </Header1>
            </Grid>
            <Hidden smDown>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/lineArt/doc.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/lineArt/submit.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/lineArt/shovel.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingLeft: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/lineArt/badge.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Paragraph style={{ lineHeight: 1.6 }}>
                Project developers (like Bluesource) plan carbon offset project.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Paragraph style={{ lineHeight: 1.6 }}>
                Project information is sent to offset oversight organizations
                for approval.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Paragraph style={{ lineHeight: 1.6 }}>
                Project is approved and implemented, offsetting carbon.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Paragraph>
                Third Party comes in to verify that carbon is properly offset.
              </Paragraph>
            </Grid>
            </Hidden>
            <Hidden mdUp>
                <Carousel
                  itemsToShow={1}
                  showArrows={false}
                  pagination={false}
                  enableAutoPlay={true}
                  autoPlaySpeed={5000}
                >
                   <img
                src={require("./../../assets/aboutOffsets/lineArt/doc.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
              <img
                src={require("./../../assets/aboutOffsets/lineArt/submit.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
              <img
                src={require("./../../assets/aboutOffsets/lineArt/shovel.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />
              <img
                src={require("./../../assets/aboutOffsets/lineArt/badge.png")}
                style={{
                  width: "75%",
                  maxHeight: "40vh",
                  objectFit: "cover",
                }}
              />

                  
                  
                </Carousel>
              </Hidden>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <hr />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Header1
                style={{
                  color: "#22422B",
                }}
              >
                Offset Safeguards and stake holders
              </Header1>
              <Paragraph style={{ lineHeight: 1.6 }}>
                A system of checks and balances exists to ensure that projects
                remove the promised CO2 from the atmosphere. The first is the
                standard. The standard sets the rules which all projects must
                follow to be official. The second is the project verifier, an
                independent third party. The verifier checks that the project
                created by the developer follows the rules set by the carbon
                offset standard.
              </Paragraph>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Paragraph>
                <b>
                  <b>Our Standard - American Carbon Registry</b>
                </b>
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.6 }}>
                The projects we support follow standards developed by the
                American Carbon Registry (ACR), an organization that employs
                world renowned offset experts to develop rigorous carbon offset
                standards. These projects are registered under ACR’s offset
                registry, meaning project developers must submit documentation
                proving that their project follows ACR’s project requirements.
                High quality carbon offset projects must be registered to
                provide transparency and proof of efficacy. Over 440 projects
                have been registered under the ACR, offsetting over 100,000,000
                tons of carbon.
              </Paragraph>
            </Grid>
            <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Paragraph>
                <b>
                  <b>Our Verifier - SCS Global Services</b>
                </b>
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.6 }}>
                SCS Global Services first began verifying carbon offset projects
                in 2007, building on its long history as an expert in forest
                management certification. It is one of the foremost experts in
                offsets, having verified over 250 million tonnes via 160
                assessments, totaling more than 18 million acres globally under
                a diverse set of standards and project types.
              </Paragraph>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <hr />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Header1
                style={{
                  color: "#22422B",
                }}
              >
                TYPES OF OFFSET PROJECTS
              </Header1>
              <Paragraph style={{ lineHeight: 1.6 }}>
                There are three main types of carbon offset forestry projects
                Carbon Down uses to offset customer emissions: reforestation,
                improved forest management, and avoided deforestation.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{
                paddingRight: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/projectTypeOne.png")}
                style={{
                  width: "100%",
                  minHeight: "30vh",
                  maxHeight: "30vh",
                  objectFit: "cover",
                }}
              />
              <Paragraph style={{ fontSize: 25, color: "#22422b" }}>
                <b>Reforestation</b>
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.6 }}>
                Carbon Down supports tree planing projects on destroyed or
                degraded lands. Reforestation is particularly important after
                forest fires, which often leaves a dead forest behind.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/projectTypeTwo.jpg")}
                style={{
                  width: "100%",
                  minHeight: "30vh",
                  maxHeight: "30vh",
                  objectFit: "cover",
                }}
              />
              <Paragraph style={{ fontSize: 25, color: "#22422b" }}>
                <b>Improved Forest Management</b>
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.6 }}>
                By paying private land owners or companies to change their
                timber harvesting practices to leave more trees standing for a
                longer period of time, more carbon can be offset relative to
                baseline carbon sequestration data.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              style={{
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <img
                src={require("./../../assets/aboutOffsets/projectTypeThree.png")}
                style={{
                  width: "100%",
                  minHeight: "30vh",
                  maxHeight: "30vh",
                  objectFit: "cover",
                }}
              />
              <Paragraph style={{ fontSize: 25, color: "#22422b" }}>
                <b>Avoided Deforestation</b>
              </Paragraph>
              <Paragraph style={{ lineHeight: 1.6 }}>
                Many of our forests are in danger of being converted to farms or
                houses. However, by buying and preserving these forests, we
                ensure they can continue sequestering carbon for centuries.
              </Paragraph>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <hr />
            </Grid>
          </Grid>
 
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
          >
            <div
              style={{
                position: "absolute",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Grid container style={{ padding: padding }}>
                <Grid item xs={0} sm={1} md={1} lg={1} xl={1} />
                <Grid item xs={11} sm={10} md={6} lg={6} xl={6}>
                  <Header1 style={{ color: "white", fontSize:'4vh'}}>
                    Project Case Study: Waldo Canyon Reforestation
                  </Header1>
                  <Paragraph style={{ color: "white", lineHeight: 1.6 }}>
                    In 2012, the Waldo Canyon Fire burned 18,000 acres of forest
                    in Colorado. Eight years later, no trees had grown back, and
                    without reforestation, no trees would.
                    <br />
                    <br />
                    But due to the efforts of Carbon Down, this forest can grow
                    back. With your support, we can continue this work, and
                    together, we can end deforestation and climate change.
                  </Paragraph>
                </Grid>
                <Grid item xs={0} sm={1} md={7} lg={7} xl={7} />
              </Grid>
            </div>
            <img
              src={require("./../../assets/aboutOffsets/caseStudy.png")}
              style={{
                height: "60vh",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            style={{ paddingRight: 0 }}
          ></Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={3}
            lg={3}
            xl={3}
            style={{ paddingTop: padding }}
          >
            <Header1 style={{ color: "#22422b" }}>
              <b>How This Project Neutralized Emissions</b>
            </Header1>
            <Paragraph style={{ fontSize: 25, color: "#22422b" }}>
              <b>Step One.</b>
            </Paragraph>
            <Paragraph>
              Fire burned down the forest badly enough it would never grow back.
            </Paragraph>
            <Paragraph style={{ fontSize: 25, color: "#22422b" }}>
              <b>Step Two.</b>
            </Paragraph>
            <Paragraph>Carbon Down paid reforestation costs.</Paragraph>
            <Paragraph style={{ fontSize: 25, color: "#22422b" }}>
              <b>Step Three.</b>
            </Paragraph>
            <Paragraph>Forest will regrow and remove CO2.</Paragraph>
          </Grid>
          <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />

        </Grid>
      </div>

      <div style={{ width: "100%", height: "70vh", position: "relative", paddingTop:50}}>
        <img
          src={require("./../../assets/aboutOffsets/footer.jpg")}
          style={{
            height: "70vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "70vh",
            display: "flex",
          }}
        >
          <Grid
            container
            style={{
              alignItems: "center",
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} style={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}>
              <Header1
                style={{
                  color: "white",
                  fontSize:'5vw',
                }}
              >
                Support a Project
              </Header1>
              <Paragraph style={{color:'white', justifySelf:'center', textAlign:'center', alignItems:'center'}}>
                We at Carbon Down are always on the lookout for new and exciting
                forestry projects around the United States.
                <br />
                <br />
                Someday soon, we'd like to bring a project to you and your city.
                <br />
                <br />
                In the meantime, feel free to view our existing catalog of
                projects.
              </Paragraph>

              <Button
                text={"View Projects"}
                onClick={() => navigate("./../")}
                width={"50%"}
                marginTop={20}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer />
    </div>
  )
}
